.cms-home {
    h1,
    h2,
    .home-products .block-title {
        color: $secondary !important;
        @include font-size($h3-font-size);
    }

    .page-title-wrapper h1.page-title .base {
        @include font-size($h2-font-size);
        color: $secondary;
        font-family: $headings-font-family;
    }

    .home-products {
        .block-link {
            a {
                &::after {
                    @include svg-icon($font-size-base, $font-size-base, $icon-arrow-right, $orange);
                    vertical-align: middle;
                }
            }
        }
        .products-grid {
            .product-item {
                .product-item-photo {
                    .product-item-attribute {
                        right: 0;
                    }
                }
            }
        }
    }

    .home-block {
        &.top-list {
            .title {
                color: $secondary;
            }
            .products-list {
                .product-number {
                    &:after {
                        @include svg-icon(3rem, 3rem, $icon-star-outline, $secondary);
                    }
                    .number {
                        color: $primary;
                    }
                }
            }

            .topper {
                &::after {
                    @include svg-icon(1rem,1rem, $icon-arrow-right, $orange);
                }
            }
        }
    }

    .home-blocks {
        &.row {}
        min-height: 140px;

        .logo-wrapper {
            position: absolute;
            padding: 0;
            width: 100%;
            left: 0;

            .logo {
                display: block;
                margin-top: -2rem;
                margin-bottom: map-get($spacers, 4);
                padding-top: 1rem;
                padding-bottom: 1rem;
                background-color: lighten($gray-100, 1);
                text-align: center;

                img {
                    max-width: 200px;
                    margin: 0 auto;
                }
            }
            &+.home-products {
                margin-top: 10rem;
            }
        }
        .top-wrapper {
            margin-bottom: map-get($spacers, 4);
        }
    }
}

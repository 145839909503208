.catalog-category-view {
    h2,
    h3 {
        color: $primary;
        font-weight: $font-weight-normal;
    }
}

.product-item-info {
    padding: 0 0.5rem;

    .product-item-photo {
        display: flex;
        margin-bottom: 1rem;

        .product-item-attribute {
            .product-label {
                &.red {
                    background-color: $secondary;

                    &::before {
                        border-color: $secondary;
                    }

                    &::after {
                        border-top: 1em solid $secondary;
                        border-color: darken($secondary, 12);
                    }

                    .arrow-corner {
                        border-left: 1em solid $secondary;
                    }
                }

                .product-label-text {
                    text-transform: uppercase;
                }
            }
        }
    }
}

.products-grid {

    .product-col,
    .widget-product-item.product-item {
        position: relative;
        padding: 0;
        border: 0.5rem solid transparent;

        &:hover,
        &:focus {
            border: 0.5rem solid lighten($orange, 15);
        }
    }

    .product-item {
        margin: 0;

        .product-item-photo {
            margin: 0 0 $spacer;

            .product-image-container {
                width: 100%;

                //.product-image-photo {
                //    width: 100%;
                //}
            }

            .product-item-attribute {
                right: -0.5rem;

                .product-label {
                    &::after {
                        bottom: -0.725rem;
                    }
                }
            }
        }

        &-name {
            color: $secondary;

            .product-item-link {
                min-height: 3.75rem;
            }
        }

        .price-box {
            color: $orange;
        }

        &-configuration {
            padding: 0;
            left: -0.8rem;
            right: -0.8rem;
            border: 0.5rem solid lighten($orange, 15);
            border-top: 0;

            &>div {
                .swatch-attribute {
                    padding: 0.75rem 0.5rem;
                }
            }
        }
    }
}

.cms-home {
    .products-grid {
        .product-item {
            &-details {
                align-items: flex-start;
                text-align: left;
            }

            &-name {
                .product-item-link {
                    text-align: left;
                }
            }
        }
    }
}

.toolbar {
    .dropdown {
        &.show {
            .dropdown-toggle {
                .icon {
                    @include svg-icon(1rem, 1rem, $icon-angle-up, $orange);
                }
            }
        }

        .dropdown-toggle {
            border-color: lighten($primary, 25);

            .icon {
                @include svg-icon(1rem, 1rem, $icon-angle-down, $orange);
            }
        }

        .dropdown-menu {
            .active {
                background-color: $primary;
            }
        }
    }

    .toolbar-sorter {
        .input-group {
            .input-group-append {
                a.btn {
                    &.sort-asc {
                        @include svg-icon(0.85rem, 0.85rem, $icon-sort-amount-down, $orange);
                    }

                    &.sort-desc {
                        @include svg-icon(0.85rem, 0.85rem, $icon-sort-amount-up, $orange);
                    }
                }
            }
        }
    }
}

.filter-options-item {
    .filter-options-title {
        color: $secondary;
        @include font-size($h4-font-size);
    }
}

.product-related-wrapper {
    .block-title {
        color: $secondary;
    }
}

.panel-layered {
    .panel-heading {
        .block-subtitle {
            color: $secondary;
        }
    }
}

.filter-options-item {
    .filter-options-content {
        .items {
            .item {
                [class*='am-filter-item-'] {
                    .count {
                        color: $primary;
                    }
                }
            }
        }
    }
}

.product-configurator,
.configurator,
.product-options-bottom,
.products-crosssell-product {
    border-color: $orange !important;
}

.product-size {
    .icon-th-large {
        @include svg-icon(0.875rem, 0.875rem, $icon-th-large, $primary);
    }
}

.product-configurator {
    .checkbox {
        span {
            color: $primary;
        }
    }
}
.product-options-bottom {
    .box-tocart {
        .form-inline {
            .spinbox {
                .input-group {
                    border-color: $primary;

                    .input-group-btn {
                        button.btn-qty {
                            .icon-minus {
                                @include svg-icon(0.75rem, 0.75rem, $icon-minus, $orange);
                            }

                            .icon-plus {
                                @include svg-icon(0.75rem, 0.75rem, $icon-plus, $orange);
                            }
                        }
                    }
                }
            }

            .actions {
                .tocart.primary {
                    background-color: $orange;
                    border-color: $orange;

                    &:focus {
                        border: 1px solid $white;
                    }

                    &:focus,
                    &:hover {
                        background-color: lighten($orange, 10);
                        border-color: lighten($orange, 10);
                    }
                }
            }
        }
    }
}

.catalog-product-view {

    .crosssell-product,
    .cart-crosssell-wrapper {
        column-gap: 0;
        
        .block-title {
            strong {
                padding: 0 $spacer;
                .title {
                    color: $orange;
                    font-weight: $font-weight-base;
                }
                
                .subtitle {
                    color: $secondary;
                }
            }
        }
    }
}

.product-review-wrapper {
    .review-list {
        .title {
            color: $secondary;
        }
    }

    .add-review {
        button.add-review {

            &.btn-cta,
            &:active {
                background-color: $orange;
                border-color: $orange;
                box-shadow: 0 10px 20px 0 $gray-200;
            }
        }
    }
}

.product-details-wrapper {
    .description, .additional-attributes {
        .title, h2, h2.label-collapse {
            color: $primary;
        }
    }

    .additional-attributes {
        .label-collapse {
            &::after {
                @include svg-icon(1.25rem, 1.25rem, $icon-minus-square-solid, $secondary);
            }

            &.collapsed {
                &::after {
                    @include svg-icon(1.25rem, 1.25rem, $icon-plus-square-solid, $secondary);
                }
            }
        }
    }
}

$iconClasses: (
    truck: $icon-truck, 
    money-bill-alt: $icon-money-bill-alt,
    money: $icon-money-bill-alt,
    star: $icon-star-sharp, 
    thumbs-up: $icon-thumbs-up, 
    phone: $icon-phone
);    
$iconSize: 1rem;

@each $iconClass, $iconValue in $iconClasses {
    .product-usp {
        .icon {
            vertical-align: middle;
            &-#{$iconClass} {
                @include svg-icon($iconSize, $iconSize, $iconValue, $secondary);
            }
        }
    }
}
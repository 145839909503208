.navbar {
    background-image: none;
    background-color: $secondary;
    padding: 0;

    @include media-breakpoint-down($main-breakpoint) {
        padding: 0 1rem;
    }

    .menu-item {
        a {
            padding: 0.85rem 1rem;
            display: inline-block;
            font-weight: $font-weight-normal;
            color: $white;
            font-size: 1rem;

            @include media-breakpoint-up($main-breakpoint) {
                padding: 0.75rem 1.286rem;
            }
        }
        &:hover {
            &>a {
                background-color: $orange;
            }
        }

        &.dropdown {
            .menu-link-normal {
                span {
                    &::after {
                        @include svg-icon(1.2rem, 1.2rem, $icon-angle-down, $orange);
                    }
                    &.fa-fa-star {
                        display: none;
                    }
                }
            }

            &:hover {
                span {
                    @include media-breakpoint-up(sm) {
                        &::after {
                            @include svg-icon(1.2rem, 1.2rem, $icon-angle-down, $white);
                        }
                    }
                }
            }

            .dropdown-menu {
                .block-category-link-inline {
                    a {
                        color: $primary;
                    }

                    @include media-breakpoint-down($main-breakpoint) {
                        margin-bottom: map-get($spacers, 1);
                    }
                }
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            a {
                color: $orange;
            }
            &:hover {
                background-color: $orange;
                a {
                    color: $white;
                }
            }
        }
    }

    #navbar-offcanvas {
        margin-left: 9.9rem;
        .offcanvas-heading {
            background-color: $primary;
        }
        .navbar-nav {
            .menu-item {
                &:last-child {
                    display: inline-block;
                }
                @include media-breakpoint-down($main-breakpoint) {
                    padding: 0;
                }
            }
        }
    }

    .navbar-header {
        .navbar-header-left {
            .navbar-toggle {
                .icon-bar {
                    background-color: $white
                }
                .menu-text {
                    color: $white;
                }
            }
        }
        .navbar-header-right {
            .navbar-search-toggle {
                .icon-search {
                    @include svg-icon(1rem, 1rem, $icon-search, $primary);

                    &::before {
                        display: none;
                    }
                }
            }

            .navbar-cart {
                .showcart {
                    &::before {
                        @include svg-icon(3rem, 2.5rem,  $icon-cart, $white);
                        transform: scale(0.65);
                    }
                }
            }
        }
    }
}

#menu-collapse0 {
    display: block !important;
}

.menu-item.dropdown {
    .dropdown-menu {
        .block-category-link-inline {
            a {
                padding: 0;
                font-size: $font-size-base;
            }
        }
    }
}
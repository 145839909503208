.offcanvas-minicart {
    .minicart-items-wrapper {
        .minicart-items {
            .product-item {
                .product-row {
                    .product-item-photo {
                        @include make-col(4);
                    }

                    .product-item-details {
                        @include make-col(7);
                    }
                }
            }
        }
    }
}

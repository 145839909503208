.catalog-product-view,
.catalog-category-view {

    h1.page-title {
        .base {
            font-family: $font-family-sans-serif;
            color: $orange;
        }
    }
}

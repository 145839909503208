footer.footer {
    .footer-about {
        background: lighten($secondary, 7);
    }

    div.footer-middle-wrapper {
        background-color: $secondary;
    }

    .footer-block {
        .block-title {
            pointer-events: none;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-down(sm) {
                pointer-events: all;
                
                &::after {
                    @include svg-icon(1rem, 1rem, $icon-plus, $white);
                }
                &.collapsed {
                    &::after {
                        @include svg-icon(1rem, 1rem, $icon-minus, $white);
                    }   
                }
            }

            h3 {
                @include font-size($h2-font-size);
                font-weight: $font-weight-normal;
            }
        }
        .contact-info {
            padding-left: 0;
            list-style: none;

            li {
                &:last-of-type {
                    margin-top: $spacer;
                }
                a {
                    color: $white;
                    
                    &::before {
                        margin-right: $spacer;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .footerseomenu {
        a {
            color: $white;
        }
    }
}

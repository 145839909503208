header.page-header {
    margin-bottom: 0;

    .top-wrapper {
        margin-bottom: 0;
        padding: 0;
        background-color: $white;

        .seomenu {
            border-bottom: ($border-width * 2) solid lighten($gray-100, 1);

            .header-block {
                padding: 1.25rem 0 0.65rem;

                ul li {
                    @extend p;
                }
            }
        }

        a, ul li {
            color: $primary;
        }

        .top-header {
            .top-usp {
                .usp-block {
                    a {
                        color: $gray-700 !important;

                        .icon-truck {
                            @include svg-icon(1.2rem, 1.2rem, $icon-truck, $primary);
                        }
                    }
                }
            }
        }
    }
}

.header {
    .header-sub {
        .logo-slogan {
            .logo-relative {
                top: -0.25rem;

                .logo {
                    display: inline-block;
                    margin-top: -1.75rem;
                    margin-bottom: 1.25rem;

                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

.btn-header-block {
    .btn-total {
        .btn-text {
            color: $secondary;
        }

        .icon {
            @include svg-icon(0.85rem, 0.85rem, $icon-close, $orange);
        }
    }

    &.collapsed {
        .btn-total {
            .icon {
                @include svg-icon(0.85rem, 0.85rem, $icon-bars, $orange);
            }
        }
    }
}

.header-minicart,
.navbar-cart {
    .icon {
        &::before {
            @include svg-icon(3rem, 2.5rem, $icon-cart, $secondary);
        }
    }

    .counter {
        background: $orange;
    }

    .counter-label {
        display: none;
    }
}

.block-search-mobile, .block-search {
    .input-group { 
        .form-control {
            border-color: lighten($gray-100, 1);
            background-color: lighten($gray-100, 1);

            .actions {
                .search {
                    background-color: lighten($gray-100, 1);
                    &:before {
                        @include svg-icon(1.5rem, 1.2rem, $icon-search, $secondary);
                    }
                }
            } 
        }
    }
 }

 #search_mobile_form {
    &.block-search-mobile {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        .form-group {
            .input-group {
                .form-control {
                    border-color: $primary;
                }
                .input-group-btn {
                    .icon-search {
                        @include svg-icon(1rem, 1rem, $icon-search, $orange);
                    }
                }
            }
        }
    }
 }